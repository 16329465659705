import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import PropertyDocuments from "@/components/PropertyDocuments.vue";
import { resolveRoutePath } from "@/router";
import * as Router from "@/router";

jest.mock("@/services/MyRentalsConfig");

const defaultProps = {
  propertyUuid: "uuid",
};

const defaultMocks = {
  $router: { push: jest.fn() },
};

const component = (data = {}, props = defaultProps, mocks = defaultMocks) => {
  return myRentalsShallowMount(PropertyDocuments, {
    data,
    props,
    mocks,
    router: true,
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("PropertyDocuments", () => {
  testMyRentalsShallowMount(PropertyDocuments, {
    props: defaultProps,
    mocks: defaultMocks,
    router: true,
  });

  describe("Methods", () => {
    describe("statusData", () => {
      it("Should go to documents page", () => {
        // given
        const propertyDocuments = component();
        spyOn(propertyDocuments.vm.$router, "push");
        // when
        propertyDocuments.vm.handleDocumentsButtonClick();
        // then
        const route = resolveRoutePath({
          name: "documents",
          params: {
            propertyUuid: defaultProps.propertyUuid,
          },
        });
        // then
        expect(propertyDocuments.vm.$router.push).toHaveBeenCalledWith(route);
      });
    });
    describe("handleDocumentsButtonClick", () => {
      it("should return correct url if currentLeasing exists", () => {
        // given
        const mockedUuid = "123-asda-234";
        const mockedLeasingId = 4554;
        const mockedProps = {
          propertyUuid: mockedUuid,
          currentLeasing: {
            leasingId: mockedLeasingId,
          },
        };
        spyOn(Router, "resolveRoutePath");
        const wrapper = component({}, mockedProps);
        // when
        wrapper.vm.handleDocumentsButtonClick();
        // then
        expect(resolveRoutePath).toHaveBeenCalled();
      });
      it("should return correct url if currentLeasing is not defined", () => {
        // given
        const mockedUuid = "123-asda-234";
        const mockedProps = {
          propertyUuid: mockedUuid,
          currentLeasing: undefined,
        };
        spyOn(Router, "resolveRoutePath");
        const wrapper = component({}, mockedProps);
        // when
        wrapper.vm.handleDocumentsButtonClick();
        // then
        expect(resolveRoutePath).toHaveBeenCalled();
      });
    });
  });
});
